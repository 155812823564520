export default {
  methods: {
    trackEvent(event) {
      if (process.client && window.dataLayer) {
        window.dataLayer.push(event);
        //window.gtag("event", event.event, event);
      }
    },
  },
};
